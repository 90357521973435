<template>
  <!-- <Dashboard> -->
    <router-view />
  <!-- </Dashboard> -->
</template>

<script>
// import Dashboard from "./layouts/Dashboard.vue"
import 'vue-advanced-cropper/dist/style.css'

export default {
  // components: {Dashboard},
  setup() {

  }
}

</script>


<style>
@import './utils/app.css';
</style>
