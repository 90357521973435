import { auth } from "@/config/firebase";
import { createWebHistory, createRouter } from "vue-router";
// import Home from "@/pages/Home.vue";
var UI = {
  default: () => import("../pages/Landing.vue"),
  HeaderBar: () => import("../components/Headerbar.vue"),
};
const requiredAuth = (to, from, next) => {
  let user = auth.currentUser;

  if (!user) {
    // redirect
    next({ name: "ClientSignin" });
  } else {
    next();
  }
};
const adminAuth = (to, from, next) => {
  let user = auth.currentUser;

  if (!user) {
    // redirect
    next("/login");
  } else {
    let adminMail = user.email === "aungkhantzaw133@gmail.com";
    if (adminMail) {
      next();
    } else {
      return "401 unauthorized";
      // next('login')
    }
  }
};

const routes = [
  {
    path: "/",
    // component: () => import("../pages/Landing.vue"),
    component: () => import("../layouts/Client"),
    children: [
      {
        name: "landing",
        path: "",
        components: {
          ...UI,
          default: () => import("../pages/Landing.vue"),
        },
      },
      {
        name: "ClientSignin",
        path: "signin",
        meta: {breadcrumbs: []},
        components: {
          ...UI,
          default: () => import("../pages/ClientSignin.vue"),
        },
      },
      {
        name: "resetPassword",
        path: "reset-password",
        meta: {breadcrumbs: []},
        components: {
          ...UI,
          default: () => import("../pages/ResetPassword.vue"),
        },
      },
      {
        name: "ProductDetail",
        path: "product/:id",
        props: true,
        components: {
          ...UI,
          default: () => import("../pages/ProductDetail.vue"),
        },
      },
      {
        name: "Shops",
        path: "shoplist",
        components: {
          ...UI,
          default: () => import("../pages/Shops.vue"),
        },
      },
      {
        name: "ClientFAQ",
        path: "faqs",
        meta: {breadcrumbs: [{name: 'Home'}, {name:'FAQs'}]},
        components: {
          ...UI,
          default: () => import("../pages/ClientFAQ.vue"),
        },
      },
      {
        name: "ShowPolicies",
        path: "/client-policies",
        meta: {breadcrumbs: [{name: 'Home'}, {name:'policies'}]},
        components: {
          ...UI,
          default: () => import("../pages/ShowPolicies.vue"),
        },
      },
      {
        name: "ClientSitePolicy",
        path: "site-policy",
        meta: {breadcrumbs: [{name: 'Home'}, {name:'policy'}]},
        components: {
          ...UI,
          default: () => import("../pages/ClientSitePolicy.vue"),
        },
      },
      {
        name: "CreateConversation",
        path: "Create-Conversation/:productId",
        beforeEnter: requiredAuth,
        components: {
          ...UI,
          default: () => import("../pages/CreateConversation.vue"),
        },
      },
      {
        name: "SignUp",
        path: "Signup",
        components: {
          ...UI,
          default: () => import("../pages/Signup"),
        },
      },
      {
        name: "createConversation",
        path: "createConversation",
        component: () => import("../pages/CreateConversation.vue"),
        beforeEnter: requiredAuth,
      },
      {
        name: "ClientProfile",
        path: "/profile",
        components: {
          ...UI,
          default: () => import("../pages/ClientProfile.vue"),
        },
        beforeEnter: requiredAuth,
        children: [
          {
            path: "chats",
            name: "ClientChat",
            component: () => import("../pages/ClientChat.vue"),
            children: [
              {
                path: "id=:id",
                name: "chatWithOwner",
                component: () => import("../pages/ChatsConversation.vue"),
                beforeEnter: requiredAuth,
              },
            ],
          },
          {
            path: "",
            name: "ClientDashboard",
            component: () => import("../pages/ClientDashboard.vue"),
          },
          {
            path: "productForm",
            name: "ClientProduct",
            component: () => import("../pages/ClientProduct.vue"),
            
          },
        ],
      },
    ],
  },
  {
    path: "/admin",
    name: "site",
    component: () => import("../layouts/Dashboard.vue"),
    children: [
      {
        name: "Dashboard",
        path: "dashboard",
        component: () => import("../pages/Home.vue"),
        // beforEnter: requiredAuth,
      },
      {
        name: "User",
        path: "UserList",
        component: () => import("../pages/UserList.vue"),
        
      },
      {
        name: 'FAQs',
        path: 'FAQs-list',
        component: () => import("../pages/FAQs.vue")
      },
      {
        name: 'FAQsForm',
        path: 'FAQs-Form',
        component: () => import("../pages/FAQsForm.vue")
      },
      {
        name: 'FAQsDetail',
        path: 'FAQs-Detail/:id',
        component: () => import("../pages/FAQsDetail.vue")
      },
      {
        name: 'Policies',
        path: 'policies/',
        component: () => import("../pages/Policies.vue")
      },
      {
        name: 'PoliciesForm',
        path: 'policiesForm/',
        component: () => import("../pages/PoliciesForm.vue")
      },
      {
        name: 'PoliciesEdit',
        path: 'policiesEdit/:id',
        component: () => import("../pages/PoliciesEdit.vue")
      },
      {
        name: "ProductList",
        path: "product-list",
        component: () => import("../pages/ProductList"),
        // beforEnter: requiredAuth,
      },
      {
        name: "CategoryList",
        path: "category-list",
        component: () => import("../pages/CategoryList"),
        // beforEnter: requiredAuth,
      },
      {
        name: "CategoryForm",
        path: "category-form",
        component: () => import("../pages/CategoryForm"),
        // beforEnter: requiredAuth,
      },
      {
        name: "ProductForm",
        path: "product-form",
        component: () => import("../pages/ProductForm"),
        // beforEnter: requiredAuth,
      },
      {
        name: "ProductEdit",
        path: "product-edit/id=:id",
        props: true,
        component: () => import("../pages/ProductEdit"),
        // beforEnter: requiredAuth,
      },
      {
        name: "AdminChat",
        path: "chatList",
        component: () => import("../pages/AdminChat"),
        children: [{ 
          path: "user/:id" ,
          name: 'ChatWithUser',
          component: () => import("../pages/AdminConversation")
        }],
      },
    ],
    beforeEnter: adminAuth,
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("../pages/Login"),
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   component: About,
  // },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  linkActiveClass: "active",
});

export default router;
