// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore} from "firebase/firestore"
import { getStorage } from "firebase/storage"
import {getAuth} from "firebase/auth"
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBP1fPVzRLr5p9uVMIAjzML-zf5pgMCZLA",
  authDomain: "yourcrazyshopchat.firebaseapp.com",
  databaseURL: "https://yourcrazyshopchat-default-rtdb.asia-southeast1.firebasedatabase.app",
  projectId: "yourcrazyshopchat",
  storageBucket: "yourcrazyshopchat.appspot.com",
  messagingSenderId: "1033661026023",
  appId: "1:1033661026023:web:99643fce47feb688541c42",
  measurementId: "G-1J1FV9BBBE"
};
// const firebaseConfig = {
//   apiKey: "AIzaSyBP1fPVzRLr5p9uVMIAjzML-zf5pgMCZLA",
//   authDomain: "yourcrazyshopchat.firebaseapp.com",
//   databaseURL: "https://yourcrazyshopchat-default-rtdb.asia-southeast1.firebasedatabase.app",
//   projectId: "yourcrazyshopchat",
//   storageBucket: "yourcrazyshopchat.appspot.com",
//   messagingSenderId: "1033661026023",
//   appId: "1:1033661026023:web:4c5b25a5724dfadd541c42",
//   measurementId: "G-N0Y6J1VJKZ"
// };

// Initialize Firebase
initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

// ini services
const db = getFirestore()
const projectStorage = getStorage()
const auth = getAuth()

export {
    db,
    projectStorage,
    auth
}