import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config';
import router from './router'
import Dialog from 'primevue/dialog';
import { auth } from './config/firebase';
import { onAuthStateChanged } from 'firebase/auth';
import i18n from './i18n'

let app
onAuthStateChanged(auth, () => {

    if(!app) {
        app = createApp(App).use(i18n).use(router);
        app.use(PrimeVue);
        app.component('DialogBox', Dialog);
        
        
        app.mount("#app")
    }
})






// createApp(App).mount('#app')
